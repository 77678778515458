@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 768px) {
  html {
    font-size: 3.6vw;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }

  /* iPhone 14 dimensions: 1170x2532px */
  .mobile-container {
    width: 375px; /* iPhone 14 width in landscape orientation */
    height: 100dvh; /* iPhone 14 height in landscape orientation */
    border: 1px solid #ccc; /* Optional: Add a border for visualization */
    overflow: hidden; /* Optional: Hide overflow content */
    margin: 0 auto; /* Center the container horizontally */
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 14px;
  }

  /* iPhone 14 dimensions: 1170x2532px */
  .mobile-container {
    width: 375px; /* iPhone 14 width in landscape orientation */
    height: 100dvh; /* iPhone 14 height in landscape orientation */
    border: 1px solid #ccc; /* Optional: Add a border for visualization */
    overflow: hidden; /* Optional: Hide overflow content */
    margin: 0 auto; /* Center the container horizontally */
  }
}

/* Intl Phone input field */
.custom-phone-input {
  flex-grow: 1;
  background: #eeeeee;
  border: none;
  border-radius: 4px;
  color: #060b1f;
  font-size: 16px;
  padding-left: 12px;
}
